@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');

.gnamain {
    .gnatop {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 2rem auto;
        margin-bottom: 0.2rem;

        .gnleft {
            width: 10%;
            margin-top: 1%;

            img {
                width: 70%;
                display: inline;
                margin: 1rem 1rem;
            }

            h3 {
                display: inline;
                line-height: 2rem;
            }

            // h5{
            //     font-weight: bold;
            //     font-size: 1.7rem;
            // }
            // h7{
            //     font-weight: bold;
            //     color: #299702;
            // }
        }

        .gnright {
            width: 0%;

            .ucbsabuttons {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .button-74 {
                    margin: 1rem 0.5rem;
                }

            }

            .button-74 {
                background-color: #badfce;
                border: 1px solid #000000;
                font-family: "Calibri";
                box-shadow: #422800 3px 3px 0 0;
                color: #422800;
                cursor: pointer;
                display: inline-block;
                font-weight: 600;
                font-size: 1rem;
                padding: 10px 28px;
                line-height: 28px;
                text-align: center;
                text-decoration: none;
                user-select: none;
                -webkit-user-select: none;
                touch-action: manipulation;
            }

            .button-74:hover {
                background-color: #fff;
            }

            .button-74:active {
                box-shadow: #422800 2px 2px 0 0;
                transform: translate(2px, 2px);
            }

            @media (min-width: 768px) {
                .button-74 {
                    min-width: 120px;
                    padding: 7px 18px;
                }
            }

            @media (max-width: 676px) {
                .button-74 {
                    min-width: 70px;
                    padding: 0 10px;
                    font-size: 1.2rem;
                    line-height: 25px;
                }
            }

            @media (max-width: 400px) {
                .button-74 {
                    min-width: 50px;
                    line-height: 23px;
                    padding: 0 5px;
                    font-size: 0.7rem;
                }

                tr {
                    font-size: 1rem;
                }
            }

            @media (max-width: 300px) {
                .button-74 {
                    min-width: 30px;
                    padding: 0 1px;
                    font-size: 0.8rem;
                }

                tr {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .gnabottom {
        width: 80%;
        display: flex;
        margin: auto;
        margin-top: 1.6rem;
        justify-content: space-between;
        align-items: center;
        font-family: "Callibri";
        font-size: 1.3rem;

        // .social {
        //     display: flex;
        //     flex-direction: column;
        //     width: 8%;
        //     i {
        //         padding: 1rem;
        //         color: #01471B;
        //     }
        // }
        .bottomcontent {
            width: 100%;
            color: #01471B;
            text-align: center;
            font-family: 'Calibri';
            font-size: 1.5rem;
        }

        // img {
        //     width: 25%;
        // }
    }
}

.ourTeam {
    width: 90%;
    margin: auto;
    padding: 2rem 0;

    .heading {
        display: flex;
        font-weight: bold;
        margin-bottom: 1rem;

        h3 {
            font-size: 2rem;
        }

        .green {
            color: #2a970292;
            margin-left: 0.5rem;
            font-weight: bold;
        }

        color: #299702;
    }

    .row {

        .col-md-3,
        .col-md-4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            font-size: 1.3rem;
            font-family: "Callibri";
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: center;
            justify-content: flex-start;

            img {
                // border-radius: 50%;
                // width: 40%;
                // height: 60%;
                width: 170px;
                height: 170px;
                border-radius: 100%;
            }

            .teamname {
                font-weight: bold;
                font-size: 1.3rem;
                color: #01471B;
            }

            p {
                margin-bottom: 0 !important;
                font-size: 1.05rem;
                color: #01471B;
            }
        }
    }
}

@media only screen and (max-width: 1330px) {

    .wj__items {
        .wj__items__item {
            width: 25%;
        }
    }
}

@media only screen and (max-width: 816px) {

    .wj__items {
        .wj__items__item {
            width: 50%;
        }
    }

}

@media only screen and (max-width: 768px) {

    .wj__items {
        flex-direction: column;
        flex-wrap: nowrap;

        .wj__items__item {
            width: 100%;
            margin: 1rem 0;
        }
    }

    .ucbsamain {
        .ucbsatop {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: center;
            margin: 2rem auto;

            .ucbsaleft {
                width: 100%;
                margin-top: 1%;
            }

            .ucbsaright {
                width: 100%;
                margin-top: 1%;
            }
        }

        .ucbsabottom {
            width: 90%;
            display: flex;
            margin: auto;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .social {
                width: 100%;
                flex-direction: row;
            }

            .bottomcontent {
                width: 90%;
                text-align: justify;
                color: #01471B;
            }

            img {
                width: 60%;
            }
        }
    }
}