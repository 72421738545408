.vision__container {
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;

    &__reverse {
        flex-direction: row-reverse;

        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    justify-content: center;

    @media screen and (max-width:800px) {
        text-align: center;
        margin-top: 2rem;
        flex-direction: column;
    }

    .vision-img-container {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;

        &__img {
            width: 100%;
            margin: auto;

            &__largeImg {
                width: 65%;

                @media screen and (max-width: 950px) {
                    width: 85%;
                }
            }

            @media screen and (max-width:600px) {
                width: 100%;
            }
        }
    }

    .vision__container__text {
        margin-left: 3rem;

        @media screen and (max-width: 800px) {
            margin-left: unset;
        }

        padding-top: 2.5rem;

        .vision__container__text__heading {
            margin-top: 1.5rem;
            color: #299702;
            font-size: 2rem;
            font: bold;
        }

        .vision__container__text__content {
            margin: auto;
            margin-top: 2rem;
            font-size: 1.5rem;
            font-family: "Callibri";
            width: 90%;
            text-align: justify;

            ul {
                list-style: circle;

                li {
                    margin-top: 0.8rem;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .vision__container__text__content {
        ul {
            padding-left: 10px;

            li {
                // list-style: none;
            }
        }
    }
}