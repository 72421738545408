#UCBSA-contact {
  margin-top: 1%;
}

.contactus-main {
  color: #01471B;

  h1 {
    margin: 2rem auto;
    text-decoration: underline;
    text-align: center;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.green-box {
  height: 34rem;
  width: 10%;
  background-color: #8cc4a9;
}

.contactus {
  padding: 4% 0;
  width: 80%;
}

.mycontent {
  padding: 1% 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #01471B;
    text-align: center;
  }

  hr {
    border: 2px solid #01471B;
    width: 80%;
    text-align: center;
  }
}

span {
  color: #01471B;
  font-size: 1.5rem;
}

.line {
  border-left: 4px solid #01471B;
  height: 45rem;
}

.cntform {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 10 10%;
  margin-right: 0;

}

.map-text {
  text-align: center;
  margin-top: 4%;
  color: #0f001a;
  font-size: 1.3rem;
}

.form-text {
  font-size: 1.3rem;
  text-align: justify;
}

.grid-container {
  border: 1px solid #01471B;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 60% 4px auto;
  padding-bottom: 2%;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 4;
}

.map-flex {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    height: 100%;
  }
}

//--------------Media Query For Tablet Size------------
@media only screen and (max-width: 1400px) {
  .line {
    height: 35rem;
  }

  .map-flex {
    padding: 0 10%;
    justify-content: center;

    img {
      height: auto;
      width: 90%;
    }
  }
}

//-----------------Media Quesry For Phne Size--------------
@media only screen and (max-width: 1190px) {
  .green-box {
    display: none;
  }

  .contactus {
    width: 100%;
  }

  .grid-container {
    border: none;
    grid-template-columns: auto;
    padding-bottom: 2%;
  }

  .contact-img {
    display: none;
  }

  .item1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .line {
    border-bottom: 4px solid #01471B;
    margin: 4%;
    height: auto;
  }
}