@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moirai+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.wj__main {
    width: 90%;
    margin: auto;
    margin-top: 3rem;
}

.wj__heading {
    font-size: 2rem;
    color: #299702;
    font-family: 'Oleo-Script' sans-serif;

    @media screen and (max-width: 900px) {
        text-align: center;
    }
}

.wj__items {
    // display: grid;
    // @media screen and (max-width:1200px) {
    //     grid-template-columns: repeat(3,1fr);
    // grid-gap: 2rem;
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    // }
    // @media screen and (max-width:850px) {
    //     grid-template-columns: repeat(2,1fr);
    // grid-gap: 2rem;
    // }
    // margin-top: 1.5rem;
    // grid-template-columns: repeat(4,1fr);
    // grid-auto-flow: row;
    // grid-gap: 4rem;
    &__item:hover {
        border-radius: 8%;
        border: 2px #527745;
        transform: scale(1.1);
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 0;
        transition: transform .2s;
        // justify-content: center;
        width: 22%;
        text-align: justify;
        background-color: #badfce;
        margin: 1rem 1rem;
        padding: 2rem;

        // border-radius: 2%;
        // box-shadow: 0.2rem 0.2rem;
        .img {
            font-family: 'Lobster', cursive;
            font-weight: bolder;
        }

        &__text {
            // margin-left: 2rem;
            // padding-top:2rem ;
            // text-decoration: dotted;
            font-size: 1.2rem;
            font-family: "Callibri";
            text-align: justify;
            width: 90%;
        }

        &__count {
            font-size: 2.3rem;
            font-weight: bold;
            color: #3A5C21;
            font-family: 'Oleo Script', cursive;
        }
    }
}

@media screen and (max-width: 500px) {
    ul {
        padding-left: 0rem;
    }
}